<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:[`header.nombre`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.activo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <div :class="$getCss(item.activo, 'activo')" dark>
            {{ $getValue(item.activo, "activo") }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ item.updated_at }}
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="1">
                <v-dialog v-model="dialog" max-width="50%" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="is('administrador')"
                      :color="$cv('principal')"
                      dark
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon blue>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-toolbar dark :color="$cv('principal')">
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                            dark
                            text
                            @click="save()"
                            v-if="!vista"
                            :disabled="!valid"
                            >Guardar</v-btn
                          >
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.nombre"
                                label="Concesionario"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.codigo_cliente"
                                label="Código de Cliente"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-autocomplete
                                :items="aseguradoras"
                                :disabled="vista"
                                item-value="id"
                                item-text="nombre"
                                label="Aseguradoras"
                                v-model="editedItem.aseguradora_id"
                                :rules="[
                                  (v) =>
                                    !!(v && v.length) ||
                                    'Este campo es requerido',
                                ]"
                                no-data-text="Este campo es requerido"
                                multiple
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="is('administrador')"
            small
            :color="$cv('btnEditar')"
            title="Editar"
            @click="
              vista = false;
              editItem(item.id);
            "
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="is('usuario')"
            small
            :color="$cv('btnVista')"
            title="Ver"
            @click="
              vista = true;
              editItem(item.id);
            "
          >
            mdi-eye-outline
          </v-icon>
          <v-icon
            v-if="is('administrador')"
            small
            :color="item.activo != 0 ? $cv('btnActivo') : $cv('btnInactivo')"
            :title="
              item.activo != 0 ? $cv('titleDesactivar') : $cv('titleActivar')
            "
            @click="$enabledItem(item)"
          >
            mdi-archive-arrow-down
          </v-icon>
          <v-icon
            v-if="is('administrador')"
            small
            :color="$cv('btnEliminar')"
            title="Eliminar"
            @click="deleteItem(item.id, item.nombre)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Concesionarios";
}

export default {
  data: (vm) => ({
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    dessertActivo: "",
    route: "concesionarios",
    routeConcAseg: "concesionarios_aseguradoras",
    routeAsegByConc: "aseguradorasByConcesionario",
    routeDelAsegByConc: "deleteAseguradorasByConcesionario",
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
    search: "",
    vista: false,
    users: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: title(), filterable: true, value: "nombre" },
      { text: "Activo", value: "activo" },
      { text: "Creado", value: "created_at" },
      { text: "Actualizado", value: "updated_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      id: "",
      concesionario_id: "",
      aseguradora_id: [],
    },
    aseguradora_id: [],
    defaultItem: {
      nombre: "",
      id: "",
      concesionario_id: "",
      aseguradora_id: [],
    },
    filters: {
      nombre: "",
      activo: "",
    },
    filterKey: [],
    selectedHeaders: [],
    aseguradoras: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  created() {
    this.$initialize();
    this.$getData(["aseguradoras"]);
    this.selectedHeaders = this.headers;
  },

  methods: {
    async deleteItem(id, nombre) {
      let vm = this;

      let titulo = vm.title;
      if (titulo.substring(titulo.length - 1) == "s")
        titulo = titulo.substring(0, titulo.length - 1);

      vm.$confirm({
        message: "¿Desea ELIMINAR el " + titulo + " " + nombre + "?",
        button: {
          no: "Cancelar",
          yes: "Eliminar",
        },

        callback: async function (confirm) {
          if (confirm) {
            Swal.alertGetInfo("Eliminando Registro");

            vm.deleteConcesionarioAseguradoras(id).then((result) => {
              if (result === true) {
                vm.$axiosApi
                  .delete(vm.route, id)
                  .then((r) => {
                    Swal.close();
                    vm.$respuesta(vm, r, 0);
                  })
                  .catch((e) => {
                    Swal.close();
                    vm.$respuesta(vm, e, 0, true);
                  });
              } else {
                Swal.close();
                vm.$respuesta(vm, result, 0, true);
              }
            });
          }
        },
      });
    },
    async editItem(id) {
      let vm = this;
      Swal.alertGetInfo("Obteniendo información <br><b></b>");

      await vm.$axiosApi
        .getById(vm.route, id)
        .then((r) => {
          this.getAseguradorasByConcesionario(id).then((result) => {
            vm.$respuesta(vm, r, 3);
            this.editedItem.aseguradora_id = result.data;
            /*if (result === true) {
                  vm.$respuesta(vm, r, 1);
                } else {
                  vm.$respuesta(vm, result, 1, true);
                }*/
          });
          //
          Swal.close();
          //vm.snackbar = false;
        })
        .catch(function (error) {
          vm.$respuesta(vm, error, 3, true);
        });
    },
    async save() {
      let vm = this;

      if (vm.$refs.form.validate()) {
        if (vm.editedIndex > -1) {
          Swal.alertGetInfo("Actualizando información");

          await vm.$axiosApi
            .put(vm.route, vm.editedIndex, vm.editedItem)
            .then((r) => {
              vm.editedItem.concesionario_id = vm.editedIndex;
              this.saveConcesionarioAseguradoras().then((result) => {
                if (result === true) {
                  vm.$respuesta(vm, r, 2);
                } else {
                  vm.$respuesta(vm, result, 2, true);
                }
              });
            })
            .catch((e) => {
              vm.$respuesta(vm, e, 2, true);
            });
        } else {
          Swal.alertGetInfo("Registrando información");

          await vm.$axiosApi
            .post(vm.route, vm.editedItem)
            .then((r) => {
              vm.editedItem.concesionario_id = r.data.data.id;
              this.saveConcesionarioAseguradoras().then((result) => {
                if (result === true) {
                  vm.$respuesta(vm, r, 1);
                } else {
                  vm.$respuesta(vm, result, 1, true);
                }
              });
              //
            })
            .catch((e) => {
              vm.$respuesta(vm, e, 1, true);
            });
        }
        Swal.close();
        vm.$close();
      }
    },

    async getAseguradorasByConcesionario(id) {
      const response = await new Promise((resolve) => {
        let vm = this;
        vm.$axiosApi
          .getById(vm.routeAsegByConc, id)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            resolve(e);
          });
      });

      return response;
    },

    async saveConcesionarioAseguradoras() {
      const valid = await new Promise((resolve) => {
        let vm = this;
        if (vm.editedIndex > -1) {
          //vm.$alertGetInfo("Actualizando información");
          let formData = vm.editedItem;
          vm.$axiosApi
            .delete(vm.routeDelAsegByConc, vm.editedIndex)
            .then((r) => {
              //vm.$respuesta(vm, r, 2);
              vm.$axiosApi
                .post(vm.routeConcAseg, formData)
                .then((r) => {
                  //vm.$respuesta(vm, r, 1);
                  resolve(true);
                })
                .catch((e) => {
                  //vm.$respuesta(vm, e, 1, true);
                  resolve(e);
                });
            })
            .catch((e) => {
              //vm.$respuesta(vm, e, 2, true);
              resolve(e);
            });
        } else {
          //vm.$alertGetInfo("Registrando información");

          vm.$axiosApi
            .post(vm.routeConcAseg, vm.editedItem)
            .then((r) => {
              //vm.$respuesta(vm, r, 1);
              resolve(true);
            })
            .catch((e) => {
              //vm.$respuesta(vm, e, 1, true);
              resolve(e);
            });
        }
      });

      return valid;
    },

    async deleteConcesionarioAseguradoras(id) {
      const valid = await new Promise((resolve) => {
        let vm = this;
        vm.$axiosApi
          .delete(vm.routeDelAsegByConc, id)
          .then((r) => {
            resolve(true);
          })
          .catch((e) => {
            resolve(e);
          });
      });

      return valid;
    },

    filterByNombre(item) {
      return this.$filterBy(item, "nombre");
    },
    filterByActivo(item) {
      return this.$filterBy(item, "activo");
    },
    formatDate(timestamp) {
      console.log(timestamp);
      if (timestamp === null) {
        return "";
      }

      const date = new Date(timestamp);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    console.log("Componente Concesionario creado");
  },
};
</script>
